<template>
  <div class="table-container">
    <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
    <v-skeleton-loader
      v-if="loading"
      type="table-heading, list-item-three-line,  list-item-three-line,  list-item-three-line"
    />
    <v-data-table
      v-if="items && loading == false"
      :headers="headers"
      :items="items"
      item-key="id"
      class="elevation-0 border-1"
      no-results-text="No results"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :footer-props="{
        'items-per-page-options': [-1],
        'disable-items-per-page': true,
      }"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.id`]="{ item }">
        <a @click="$router.push('/serp-analysis/report/' + item.id)">{{ item.id }}</a>
      </template>
      <template v-slot:[`item.keyword`]="{ item }">
        <div class="domain-list">
          <a v-if="item.keyword" :href="googleLink(item.keyword)" target="_blank" class="col-keyword">{{
            item.keyword
          }}</a>
        </div>
      </template>
      <template v-slot:[`item.compurl`]="{ item }">
        <div class="url-domain">
          <div class="domain-list">
            <a :href="item.compurl" target="blank">{{ item.compurl }}</a>
          </div>
        </div>
      </template>
      <template v-slot:[`item.comp_urls`]="{ item }">
        <div class="url-domain">
          <div class="domain-list">
            <a v-for="(url, index) in item.comp_urls" :key="index" :href="url" target="_blank" class="url-domain">{{
              url
            }}</a>
          </div>
        </div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn color="error" icon @click="showDialog(item)">
          <v-icon color="error"> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-alert icon="mdi-information" v-else-if="items.length == 0 && loading == false" prominent text>
      No data available</v-alert
    >

    <dialog-delete-report
      :show.sync="dialog_delete.show"
      :item="dialog_delete.item"
      title="Are you sure to delete this report?"
      description="This process is irreversible, and can no longer be recovered, would you like to continue?"
      :delete="deleteReport"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import DialogDeleteReport from '@/components/dialog/DialogDelete.vue'

export default {
  components: {
    DialogDeleteReport,
  },
  props: {
    loading: Boolean,
    items: Array,
    refresh: Function,
  },
  data() {
    return {
      itemsPerPage: 10,
      headers: [
        {
          text: 'User',
          align: 'start',
          value: 'user.email',
        },
        {
          text: 'Report ID',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Report Name',
          align: 'start',
          value: 'report_name',
        },
        {
          text: 'Keyword',
          align: 'start',
          value: 'keyword',
        },
        {
          text: 'Compare Domain',
          align: 'start',
          value: 'comp_urls',
        },
        {
          text: 'Created at',
          align: 'start',
          value: 'created_at',
        },
        { text: 'Actions', value: 'action', sortable: false, width: '100' },
      ],
      dialog_delete: {
        item: {},
        show: false,
      },
    }
  },
  methods: {
    ...mapActions({
      delete: 'serp_analysis/delete',
      setSnackbar: 'snackbar/set',
    }),
    async showDialog(item) {
      this.dialog_delete.show = true
      this.dialog_delete.item = item
    },
    async deleteReport() {
      this.$emit('update:loading', true)
      this.dialog_delete.show = false

      await this.delete(this.dialog_delete.item.id)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.status == 'ok') {
              this.setSnackbar({
                status: true,
                text: 'Record was successfully deleted.',
                color: 'primary',
              })
            }
          }

          this.dialog_delete.item = null
          this.refresh()
        })
        .finally(() => {
          this.$emit('update:loading', false)
        })
    },
    formatDate(value) {
      return moment(value).fromNow()
    },
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
  },
}
</script>

<style scoped>
.table-container {
  padding: 0px 0px 20px 0px;
}
.v-data-table >>> td {
  min-width: 120px;
}
.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.url-domain {
  max-width: 300px !important;
  word-break: break-all !important;
}
.domain-list {
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .col-keyword {
    min-width: 200px !important;
  }
  .url-domain {
    min-width: 300px !important;
  }
}
</style>

<template>
  <div>
    <div v-if="hasUserPermission('view reports')">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Dashboard</v-card-title>
          </v-card>
        </v-col>

        <v-col md="3" cols="12" v-if="hasUserPermission('view keyword research')">
          <v-card class="p-5">
            <v-card-title>KW Research Total:</v-card-title>
            <v-card-subtitle>{{ data.keywordCount }}</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col md="3" cols="12" v-if="hasUserPermission('view serp analysis')">
          <v-card class="p-5">
            <v-card-title>SERP Total:</v-card-title>
            <v-card-subtitle>{{ data.serpCount }}</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col md="3" cols="12" v-if="hasUserPermission('view semantic analysis')">
          <v-card class="p-5">
            <v-card-title>Semantic Total:</v-card-title>
            <v-card-subtitle>{{ data.semanticCount }}</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col md="3" cols="12" @click="setTab(4)" v-if="hasUserPermission('view full report')">
          <v-card class="p-5">
            <v-card-title>Full Reports Total:</v-card-title>
            <v-card-subtitle>{{ data.fullCount }}</v-card-subtitle>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-tabs v-model="dashboardTab" show-arrows>
            <v-tab v-for="(tab, i) in tabs" :key="i">
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items id="tabs_content" v-model="dashboardTab">
            <v-tab-item>
              <v-card v-if="hasUserPermission('view keyword research')">
                <v-card-text>
                  <keyword-table
                    v-if="data.keywordReports"
                    :loading.sync="loading"
                    :items="data.keywordReports"
                    :refresh="getViewData"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card v-if="hasUserPermission('view semantic analysis')">
                <v-card-text>
                  <serp-table
                    v-if="data.serpReports"
                    :loading.sync="loading"
                    :items="data.serpReports"
                    :refresh="getViewData"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card v-if="hasUserPermission('view serp analysis')">
                <v-card-text>
                  <semantic-table
                    v-if="data.semanticReports"
                    :loading.sync="loading"
                    :items="data.semanticReports"
                    :refresh="getViewData"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card v-if="hasUserPermission('view full report')">
                <v-card-text>
                  <full-table
                    v-if="data.fullReports"
                    :loading.sync="loading"
                    :items="data.fullReports"
                    :refresh="getViewData"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>
import { onUnmounted, ref } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
//components
import KeywordTable from './components/KeywordTable.vue'
import SerpTable from './components/SerpTable.vue'
import SemanticTable from './components/SemanticTable.vue'
import FullTable from './components/FullTable.vue'

import { mdiAccountOutline, mdiLockOutline, mdiBookmarkOutline, mdiBellOutline, mdiLinkVariant } from '@mdi/js'

export default {
  components: {
    KeywordTable,
    SerpTable,
    SemanticTable,
    FullTable,
  },

  setup() {
    const dashboardTab = ref(null)

    const tabs = [
      { title: 'Keyword Reports' },
      { title: 'SERP Reports' },
      { title: 'Semantic Reports' },
      { title: 'Full Reports' },
    ]

    return {
      tabs,
      dashboardTab,
    }
  },
  data() {
    return {
      reports: [],
      loading: false,
      data: {},
    }
  },

  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  mounted() {
    if (hasUserPermission('view keyword research')) {
      this.showKw = true
    } else if (hasUserPermission('view serp analysis')) {
      this.showSERP = true
    } else if (hasUserPermission('view semantic analysis')) {
      this.showSA = true
    } else if (hasUserPermission('view full report')) {
      this.showFull = true
    }
  },
  methods: {
    ...mapActions({
      getDashboard: 'dashboard/getDashboard',
    }),
    async getViewData() {
      this.loading = true
      await this.getDashboard()
        .then(resp => {
          if (resp && resp.data) {
            this.data = resp.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(value) {
      return moment(value).fromNow()
    },
  },
  mounted() {
    this.getViewData()
  },
}
</script>

<style scoped>
.v-data-table >>> td {
  min-width: 120px;
}
.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.url-domain {
  max-width: 300px !important;
  word-break: break-all !important;
}
.domain-list {
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .col-keyword {
    min-width: 200px !important;
  }
  .url-domain {
    min-width: 300px !important;
  }
}
</style>>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table-heading, list-item-three-line,  list-item-three-line,  list-item-three-line"}}):_vm._e(),(_vm.items && _vm.loading == false)?_c('v-data-table',{staticClass:"elevation-0 border-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","no-results-text":"No results","items-per-page":_vm.itemsPerPage,"hide-default-footer":"","footer-props":{
      'items-per-page-options': [-1],
      'disable-items-per-page': true,
    },"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
    var item = ref.item;
return [(item.type === 'paid')?_c('span',[_vm._v(_vm._s('Paid')+" ")]):_vm._e(),(item.type === 'organic')?_c('span',[_vm._v(" "+_vm._s('Organic')+" ")]):_vm._e(),(item.type === 'ranked_keywords')?_c('span',[_vm._v(_vm._s('Ranked Keywords')+" ")]):_vm._e(),(item.type === 'cluster_keywords')?_c('span',[_vm._v(_vm._s('Cluster Keywords')+" ")]):_vm._e()]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.$router.push('/kw-research/report/' + item.id)}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.client_url",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"url-domain"},[_c('div',{staticClass:"domain-list"},[_c('a',{attrs:{"href":item.client_url,"target":"blank"}},[_vm._v(_vm._s(item.client_url))])])])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.showDialog(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)}):(_vm.items.length == 0 && _vm.loading == false)?_c('v-alert',{attrs:{"icon":"mdi-information","prominent":"","text":""}},[_vm._v(" No data available")]):_vm._e(),_c('dialog-delete-report',{attrs:{"show":_vm.dialog_delete.show,"item":_vm.dialog_delete.item,"title":"Are you sure to delete this report?","description":"This process is irreversible, and can no longer be recovered, would you like to continue?","delete":_vm.deleteReport},on:{"update:show":function($event){return _vm.$set(_vm.dialog_delete, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }